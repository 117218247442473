import React from "react";

import {
  Button,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Upload,
  Result,
  message,
} from "antd";

import {
  MinusCircleOutlined,
  PlusOutlined,
  InboxOutlined,
  RightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SmileOutlined,
} from "@ant-design/icons";

import axios from "axios";

function upload(values) {
  console.log(values.upload.fileList);
  axios
    .post(
      "https://concorsopaoloferro.edu.it/concorso-api/forms/upload-test.php",
      {
        partiture: values.upload.fileList,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((result) => {
      console.log(result.data);
    });
}

function TestUpload() {
  return (
    <Form onFinish={upload} enctype="multipart/form-data">
      <Form.Item
        required
        rules={[{ required: true }]}
        label="Partiture in PDF"
        name="upload"
        requiredMark="optional"
      >
        <Upload.Dragger
          multiple
          accept=".pdf"
          beforeUpload={(file) => {
            console.log(file);
            return false;
          }}
          onChange={(info) => {
            const { status } = info.file;
            if (status !== "uploading") {
              console.log(info.file, info.fileList);
            }
            if (status === "done") {
              message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === "error") {
              message.error(`${info.file.name} file upload failed.`);
            }
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Carica le partiture</p>
          <p className="ant-upload-hint">
            Clicca o trascina i file per caricarli. Sono ammessi solo file PDF
          </p>
        </Upload.Dragger>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "80%", borderRadius: 30 }}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

export default TestUpload;
